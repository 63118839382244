<template>
    <div class="row g-5 g-xxl-8 mx-0">
        <div class="card mb-5 mb-xl-10 py-2" id="kt_profile_details_view">
            <div class="card-header cursor-pointer px-0">
                <div class="card-title m-0">
                    <h3 class="fw-bolder m-0">{{ $t("Profile Details") }}</h3>
                </div>
            </div>
            <ProfileDetails
                :profileDetails="user"
                :businessData="business"
            />
        </div>

        <div class="active-banks-section" ref="active-banks">
            <div class="card pt-3 mt-0 p-0">
                <div class="card-header px-10">
                    <div class="card-title m-0">
                        <h3 class="fw-bolder m-0">{{ $t("Active Banks") }}
                        </h3>
                        <span class="text-with-bg ms-3">{{ banks.length }}</span>
                    </div>

                    <AddBanks
                        :isOwn="true"
                        v-if="isAddBanksVisible"
                        :isAddBanksVisible="isAddBanksVisible"
                        :isEditBank="isEditBank"
                        :userInfo="user"
                        :dataForEdit="dataForEdit"
                        @handle-close="isAddBanksVisible = false"
                        @handle-save="saveBanks"
                    />

                    <div v-if="visibleDeleteModal">
                        <ConfirmationModal
                            title="Deleting Bank"
                            description="Are you sure, you want to delete this bank"
                            btnName="Delete"
                            :popupVisible="visibleDeleteModal"
                            @handleClose="visibleDeleteModal = false"
                            @handleConfirm="deleteBank"
                        />
                    </div>
                    <button
                        class="engage-filter-toggle fw-bold fs-4 align-self-center btn btn-light-primary py-1"
                        @click="openAddBanksModal"
                    >
                        <inline-svg width="15" class="me-5" src="/media/buying/icons/plus.svg" />
                        {{ $t("Add Bank") }}
                    </button>
                </div>
                <BusinessBanksTable
                    :showAction="true"
                    :banksData="banks"
                    @handle-edit="editBank"
                    @handle-delete="visibleDeleteModal = true; bankData = $event"
                />
            </div>
        </div>

        <div class="card mb-5 mb-xl-10 p-0" id="kt_profile_details_view1">
            <div class="card-header cursor-pointer px-10 py-5 align-items-center">
                <div class="card-title m-0">
                    <h3 class="fw-bolder m-0">{{ $t("Active Product Areas") }}</h3>
                </div>
                <router-link
                  class="engage-filter-toggle fw-bold fs-4 align-self-center btn btn-light-primary py-1"
                  v-if="!authUserProfileInfo.product_area_data || !Object.keys(authUserProfileInfo.product_area_data).length"
                  to="/business/settings/user-settings/settings"
                >
                  <inline-svg width="15" class="me-5" src="/media/buying/icons/plus.svg" />
                  {{ $t("Add Product Areas") }}
                </router-link>
                <!--        <el-select class="selectProductArea" v-model="value" :placeholder="$t('Select Product Area')">-->
                <!--          <el-option-->
                <!--            v-for="item in options"-->
                <!--            :key="item.value"-->
                <!--            :label="item.label"-->
                <!--            :value="item.value"-->
                <!--            :disabled="item.disabled"-->
                <!--          />-->
                <!--        </el-select>-->
            </div>
            <ProductAreas
                v-if="authUserProfileInfo && authUserProfileInfo.product_area_data"
                :profileInfo="authUserProfileInfo"
                :page="APP_PAGES.BUSINESS.PROFILE_OVERVIEW"
            />
        </div>

        <!--    <div class="card pt-3 p-0">-->
        <!--      <div class="card-header cursor-pointer px-10">-->
        <!--        <div class="card-title m-0">-->
        <!--          <h3 class="fw-bolder m-0">{{ $t("Your Activity") }}-->
        <!--            <span class="text-with-bg ms-3">0</span>-->
        <!--          </h3>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--      <div class="coming-soon-section">-->
        <!--        {{ $t('Coming Soon') }}-->
        <!--      </div>-->
        <!--      <div class="d-flex align-items-center justify-content-between px-10 mt-7">-->
        <!--        <p class="fs-4 fw-bold text-gray-600">“Questions replied monthly” by Kolt Inc</p>-->
        <!--        <p class="fs-4 fw-bold">10.021 questions replied</p>-->
        <!--      </div>-->
        <!--      <BarChart />-->
        <!--    </div>-->
    </div>
</template>
<script>
import ProductAreas from "@/buying-teams/shared-components/settings/ProductAreas";
import ProfileDetails from "@/buying-teams/shared-components/settings/ProfileDetails";
import BanksList from "@/buying-teams/shared-components/settings/BanksList";
import UsersList from "@/buying-teams/shared-components/settings/UsersList";
import AdminsList from "@/buying-teams/shared-components/settings/AdminsList";
import BarChart from "@/buying-teams/shared-components/charts/BarChart";
import store from "@/store";
import BusinessBanksTable from "@/buying-teams/shared-components/settings/BusinessBanksTable";
import AddBanks from "@/buying-teams/shared-components/settings/AddBanks";
import ConfirmationModal from "@/buying-teams/shared-components/modals/ConfirmationModal";
import { BusinessBankModel } from "@/store/models/BusinessBankModel";
import { BusinessFilterService } from "@/core/services/BusinessFilterService";
import { DataService } from "@/core/services/DataService";
import { APP_PAGES } from "@/core/config/constants";

export default {
    name: "Overview",

    components: {
        ConfirmationModal,
        AddBanks,
        BusinessBanksTable,
        ProfileDetails,
        BanksList,
        AdminsList,
        UsersList,
        ProductAreas,
        BarChart
    },

    data() {
        return {
            visibleDeleteModal: false,
            isAddBanksVisible: false,
            isEditBank: false,
            dataForEdit: null,
            options: [],
            value: "",
            bankData: null,
            initialBankValues: null,
            APP_PAGES
        };
    },

    computed: {
        user() {
            return store.getters.currentUser;
        },
        business() {
            return store.getters.business;
        },
        banks() {
            return store.getters.currentUser.business_banks;
        },
        allBanks() {
            return store.getters.allBanks;
        },
        allCountries() {
            return store.getters.allCountries;
        },
        businessBanks() {
            return store.getters.businessBanks;
        },
        authUserProfileInfo() {
            return store.getters.authUserProfileInfo;
        }
    },

    methods: {
        openAddBanksModal() {
            this.isAddBanksVisible = true;
            this.isEditBank = false;
        },
        editBank(data) {
            this.dataForEdit = data;
            this.initialBankValues = { ...data };
            this.isEditBank = true;
            this.isAddBanksVisible = true;
        },
        async deleteBank() {
            let data = [...this.banks];
            data.splice(this.banks.indexOf(this.bankData), 1);

            let params = {
                id: this.user.id,
                business_id: this.user.business_id,
                business_banks: data.map(item => {
                    return {
                        bank_name: item.name,
                        countries: item.countries
                    };
                }),
                product_areas: this.user.product_areas
            };
            await store.dispatch("updateProfileDetails", params).then(res => {
                if (res) {
                    if (this.user.isAdmin) {
                        this.user.removeBank(this.bankData.id);
                        DataService.storeAuthUserData(this.user);
                    } else {
                        BusinessFilterService.deleteBankInFilters(this.bankData, this.bankData.countries);
                    }
                }
            });
        },
        saveBanks(data) {
            let userData = this.user;
            let newBank = new BusinessBankModel(data);
            let existingUserBank = userData.business_banks.find(u_bank => u_bank.id === newBank.id);
            let existingBusinessBanks = this.businessBanks.find(u_bank => u_bank.id === newBank.id);

            if (!existingBusinessBanks) {
                BusinessFilterService.addNewBankInFilters(newBank);
            }

            if (!existingUserBank) {
                userData.addBank(newBank);
                DataService.storeAuthUserData(userData);
            } else {
                if (!this.user.isAdmin) {
                    let editedItem = [];
                    this.initialBankValues.countries.forEach(val => {
                        if (!newBank.countries.includes(val)) {
                            editedItem.push(val);
                        }
                    });
                    BusinessFilterService.mergeBanksCountries(data, editedItem);
                }
                userData.business_banks.forEach(val => {
                    if (val.id === newBank.id) {
                        val.countries = newBank.countries;
                    }
                });
            }
        },
        toActiveBanksSection() {
            setTimeout(() => {
                const hash = this.$route.hash;
                if (hash) {
                    const section = this.$refs[hash.replace("#", "")];
                    section.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            }, 0);
        }
    },

    mounted() {
        this.toActiveBanksSection();
    }
};
</script>
<style lang="scss">
.active-banks-section {
    padding: 0;
    margin: 0;
    padding-top: 128px;
    margin-top: -128px;
}

.selectProductArea {
    .el-input__inner {
        background: #F6F6F6;
        border: 1px solid #435BF4;
        border-radius: 26px;
        height: 45px;
        padding: 0 20px;
    }
}
</style>
